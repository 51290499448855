import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ScheduleBase } from '@app/reducers/orm/schedule/schedule.model';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

// needed for translation extraction
const WEEK_DAYS = [_('Mo'), _('Tu'), _('We'), _('Th'), _('Fr'), _('Sa'), _('Su')];

@Pipe({
  name: 'recurringScheduleHtml',
  standalone: true,
})
export class RecurringScheduleHtmlPipe implements PipeTransform {
  public constructor(private translate: TranslateService, private sanitizer: DomSanitizer) {}

  public transform(schedule: ScheduleBase): SafeHtml | null {
    if (schedule?.interval) {
      const base =
        schedule.interval === '1'
          ? this.translate.instant('Every week on')
          : this.translate.instant('Every {{interval}} weeks on', { interval: schedule.interval });

      return this.sanitizer.bypassSecurityTrustHtml(
        `${base}: 
          <br/>${WEEK_DAYS.filter((day) => schedule[day.toLowerCase()])
            .map((day) => this.translate.instant(day))
            .join(', ')}`
      );
    }
    return null;
  }
}
